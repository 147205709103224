@import "../../../styles/base/abstracts/";

.hbs-sibling-navigation-list-item {
  @include padding($spacing-xxs x $spacing-xxs);

  align-items: baseline;
  display: block;
  position: relative;

  @include hover {
    &::before {
      color: $c-theme-solid;
    }
  }
}

.hbs-sibling-navigation-list-item__link {
  @include subtle-link($target: ".hbs-sibling-navigation-list-item__text");
  display: block;
  width: 100%;
}

.hbs-sibling-navigation-list-item--current {
  @include unlink($target: ".hbs-sibling-navigation-list-item__text");
  color: $c-spot;
  font-weight: $fw-semibold;

  .hbs-sibling-navigation-list-item__link {
    display: inline-block;

    :hover {
      cursor: initial;
    }
  }

  .hbs-sibling-navigation-submenu-list {
    display: inline-block;
    font-weight: $fw-reg;
  }
}
