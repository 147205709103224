@import "../../../styles/base/abstracts/";

.hbs-sibling-navigation-submenu-list {
  @include padding($spacing-xxs x x $spacing-md);
  position: relative;
  width: 100%;

  .hbs-sibling-navigation-list-item__link {
    @include subtle-link($target: ".hbs-sibling-navigation-list-item__text");
  }
}
