@import "../../../styles/base/abstracts";

.hbs-aside {
  margin-top: $spacing-lg;

  &:first-child,
  .hbs-aside:first-child & {
    margin-top: 0;
  }

  @include mq($bp-desktop-xl) {
    &:first-child {
      border-top: none;
    }
  }
}

.hbs-aside .hbs-aside__title,
.hbs-aside .hbs-aside__content {
  @include baseline-text;
}

.hbs-aside .hbs-aside__title {
  font-weight: $fw-semibold;
}

.hbs-aside .hbs-aside__content {
  color: $c-text-light;
  transition: transform $transition-sm;

  p {
    @include margin($spacing-xxs x);
  }
}
