@use "sass:math";
@import "../../../../styles/base/abstracts";

.hbs-detail-page-body {
  @include grid-parent;
  @include site-max-width;
  @include block-spacing(both, padding, xl);

  align-items: baseline;

  &--has-right-rail {
    .hbs-global-align-wide,
    .hbs-global-align-full {
      @include mq($bp-desktop) {
        margin-right: inherit;
      }

      @include mq($bp-desktop-xl) {
        margin-right: inherit;
      }
    }

    .hbs-detail-page-body__main {
      @include mq($bp-desktop-lg) {
        width: $col-8;
      }
    }
  }
}

.hbs-detail-page-body__aside,
.hbs-detail-page-body__main {
  @include grid-child;
}

.hbs-detail-page-body__main {
  margin-top: $spacing-lg;

  @include mq($bp-desktop) {
    margin-right: auto;
    margin-top: 0;
    width: $col-9;
  }

  @include mq($bp-desktop-xl) {
    width: $col-8;
  }
}

.hbs-detail-page-body__content {
  display: flow-root;
}

.hbs-detail-page-body__extra-content {
  @include grid-parent;
  @include site-max-width;
  align-items: baseline;
}

.hbs-detail-page-body__extra-content-main {
  @include grid-child;

  @include mq($bp-desktop) {
    margin-left: $col-4;
    margin-right: 0;
    width: $col-9;
  }

  @include mq($bp-desktop-xl) {
    width: $col-8;
  }
}

.hbs-detail-page-body__extra-content-inner {
  display: flow-root;

  // Align full width
  > .hbs-global-align-full {
    @include mq($bp-desktop) {
      margin-left: calc(#{math.div($col-8, $col-9) * 50%} - 50vw - 2px);
      margin-right: calc(#{math.div($col-3, $col-9) * -100%} - 2.6666vw);
    }

    @include mq($bp-desktop-xl) {
      margin-left: calc(50% - 50vw);
      margin-right: calc(50% - 50vw);
    }
  }
}
