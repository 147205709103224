@import "../../../styles/base/abstracts/";

.hbs-sibling-navigation {
  width: 100%;

  @include mq($bp-desktop) {
    max-height: calc(100vh - (#{$grid-padding * 2} + #{$header-shift}));
    overflow-y: auto;
  }
}

.hbs-sibling-navigation__title {
  @include baseline-text;
  display: none;
  font-weight: $fw-semibold;
  margin-bottom: $spacing-xxs;
  position: relative;

  @include mq($bp-desktop) {
    display: block;
  }

  a {
    @include subtle-link($c-text);
  }
}

.hbs-sibling-navigation__title--mobile {
  @include baseline-text;
  display: block;
  font-weight: $fw-semibold;
  margin-bottom: $spacing-xxs;
  position: relative;
  transform: translateX(0);

  @include mq($bp-desktop) {
    display: none;
  }

  a {
    @include subtle-link($c-text);
  }
}

.hbs-sibling-navigation__list {
  @include baseline-text;
  display: none;
  margin-bottom: 0;

  @include mq($bp-desktop) {
    display: block;
    padding-bottom: $spacing-lg;
  }
}

.hbs-sibling-navigation__select-wrapper {
  position: relative;

  &::after {
    @include pseudo(x $spacing-sm #{$spacing-lg - 2} x);
    @include size(8px);
    border-bottom: $border-solid;
    border-right: $border-solid;
    margin-right: $spacing-xxs;
    transform: rotate(45deg);
  }

  @include mq($bp-desktop) {
    display: none;
  }
}

.hbs-sibling-navigation__select {
  @include baseline-text;
  @include padding($spacing-sm);
  appearance: none;
  background-color: $c-theme-solid-inverse;
  border-radius: $border-radius;
  border: $border;
  color: $c-theme-solid;
  cursor: pointer;
  display: block;
  padding-right: $spacing-lg;
  text-overflow: ellipsis;
  transition: border-color $transition;
  width: 100%;

  &:focus {
    outline: $outline;
  }

  @include hover {
    border-color: $c-theme-solid;
  }
}
